import { FSharpMap__Remove, ofSeq } from "../fable_modules/fable-library-js.4.20.0/Map.js";
import { filter, exists, map, toList } from "../fable_modules/fable-library-js.4.20.0/Seq.js";
import { toString } from "../fable_modules/fable-library-js.4.20.0/Types.js";
import { stringHash, defaultOf, equals, comparePrimitives } from "../fable_modules/fable-library-js.4.20.0/Util.js";
import { join, substring, endsWith } from "../fable_modules/fable-library-js.4.20.0/String.js";
import { op_Division, compare, equals as equals_1, fromParts } from "../fable_modules/fable-library-js.4.20.0/Decimal.js";
import Decimal from "../fable_modules/fable-library-js.4.20.0/Decimal.js";
import { Calculation_Multiplier, Calculation_BinDrakeInput_TryCreate_Z412DF242, Calculation_AllowStacking, Calculation_Speed, Calculation_CalcMode, Calculation_ContainerMode, Calculation_Container, Calculation_Item, Calculation_LWH, Calculation_Quantity_TryCreate_27AED5E3, Calculation_Weight_TryCreate_Z5095E5A6, Calculation_Tresholds, Calculation_Dim_TryCreate_Z5095E5A6 } from "../Domain/Model.js";
import { fromInt32, toInt64 } from "../fable_modules/fable-library-js.4.20.0/BigInt.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.20.0/Result.js";
import { sequenceValidationA } from "../fable_modules/FsToolkit.ErrorHandling.4.17.0/List.fs.js";
import { parse } from "../fable_modules/fable-library-js.4.20.0/Int32.js";
import { groupBy } from "../fable_modules/fable-library-js.4.20.0/Seq2.js";
import { head, map as map_1, filter as filter_1, singleton, length } from "../fable_modules/fable-library-js.4.20.0/List.js";
import { Msg } from "../MVU/BinDrakeInputForm.js";
import { Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4 } from "../fable_modules/Fable.Lit.1.4.2/LitElement.fs.js";

export function onSubmit(host, model, dispatch) {
    return (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        const formData = new FormData(form);
        const kvp = ofSeq(toList(map((tupledArg) => [tupledArg[0], toString(tupledArg[1])], formData.entries())), {
            Compare: comparePrimitives,
        });
        const kvp_1 = equals(host.getAttribute("aimode"), defaultOf()) ? FSharpMap__Remove(kvp, "aiText") : kvp;
        const multiplier = (exists((tupledArg_2) => {
            const v_1 = tupledArg_2[1];
            if (toString(v_1).indexOf(",") >= 0) {
                return true;
            }
            else {
                return toString(v_1).indexOf(".") >= 0;
            }
        }, filter((tupledArg_1) => !endsWith(tupledArg_1[0].toLowerCase(), "weight"), formData.entries())) ? 100 : 1) | 0;
        const containerUnitText = formData.get("xcontainer-unit");
        const itemUnitText = formData.get("xitem-unit");
        let patternInput;
        let matchResult;
        switch (itemUnitText) {
            case "ft": {
                switch (containerUnitText) {
                    case "in": {
                        matchResult = 0;
                        break;
                    }
                    case "ft": {
                        matchResult = 2;
                        break;
                    }
                    default:
                        matchResult = 3;
                }
                break;
            }
            case "in": {
                switch (containerUnitText) {
                    case "ft": {
                        matchResult = 1;
                        break;
                    }
                    case "in": {
                        matchResult = 2;
                        break;
                    }
                    default:
                        matchResult = 3;
                }
                break;
            }
            default:
                switch (containerUnitText) {
                    case "ft":
                    case "in": {
                        matchResult = 3;
                        break;
                    }
                    default:
                        matchResult = 4;
                }
        }
        switch (matchResult) {
            case 0: {
                patternInput = [fromParts(12, 0, 0, false, 0), fromParts(1, 0, 0, false, 0)];
                break;
            }
            case 1: {
                patternInput = [fromParts(1, 0, 0, false, 0), fromParts(12, 0, 0, false, 0)];
                break;
            }
            case 2: {
                patternInput = [fromParts(1, 0, 0, false, 0), fromParts(1, 0, 0, false, 0)];
                break;
            }
            case 3: {
                patternInput = [fromParts(1, 0, 0, true, 0), fromParts(1, 0, 0, true, 0)];
                break;
            }
            default: {
                const itemUnit = new Decimal(itemUnitText);
                const containerUnit = new Decimal(containerUnitText);
                patternInput = (equals_1(itemUnit, containerUnit) ? [fromParts(1, 0, 0, false, 0), fromParts(1, 0, 0, false, 0)] : ((compare(itemUnit, containerUnit) > 0) ? [op_Division(itemUnit, containerUnit), fromParts(1, 0, 0, false, 0)] : [fromParts(1, 0, 0, false, 0), op_Division(containerUnit, itemUnit)]));
            }
        }
        const containerWUnit = formData.get("wcontainer-unit");
        const itemWUnit = formData.get("witem-unit");
        let patternInput_1;
        let matchResult_1;
        switch (itemWUnit) {
            case "lbs": {
                switch (containerWUnit) {
                    case "oz": {
                        matchResult_1 = 0;
                        break;
                    }
                    case "lbs": {
                        matchResult_1 = 2;
                        break;
                    }
                    default:
                        matchResult_1 = 3;
                }
                break;
            }
            case "oz": {
                switch (containerWUnit) {
                    case "lbs": {
                        matchResult_1 = 1;
                        break;
                    }
                    case "oz": {
                        matchResult_1 = 2;
                        break;
                    }
                    default:
                        matchResult_1 = 3;
                }
                break;
            }
            default:
                switch (containerWUnit) {
                    case "oz":
                    case "lbs": {
                        matchResult_1 = 3;
                        break;
                    }
                    default:
                        matchResult_1 = 4;
                }
        }
        switch (matchResult_1) {
            case 0: {
                patternInput_1 = [fromParts(16, 0, 0, false, 0), fromParts(1, 0, 0, false, 0)];
                break;
            }
            case 1: {
                patternInput_1 = [fromParts(1, 0, 0, false, 0), fromParts(16, 0, 0, false, 0)];
                break;
            }
            case 2: {
                patternInput_1 = [fromParts(1, 0, 0, false, 0), fromParts(1, 0, 0, false, 0)];
                break;
            }
            case 3: {
                patternInput_1 = [fromParts(1, 0, 0, true, 0), fromParts(1, 0, 0, true, 0)];
                break;
            }
            default: {
                const itemUnit_2 = new Decimal(itemWUnit);
                const containerUnit_2 = new Decimal(containerWUnit);
                patternInput_1 = (equals_1(itemUnit_2, containerUnit_2) ? [fromParts(1, 0, 0, false, 0), fromParts(1, 0, 0, false, 0)] : ((compare(itemUnit_2, containerUnit_2) > 0) ? [op_Division(itemUnit_2, containerUnit_2), fromParts(1, 0, 0, false, 0)] : [fromParts(1, 0, 0, false, 0), op_Division(containerUnit_2, itemUnit_2)]));
            }
        }
        const weightMultiplier = (exists((tupledArg_4) => {
            const v_2 = tupledArg_4[1];
            if (toString(v_2).indexOf(",") >= 0) {
                return true;
            }
            else {
                return toString(v_2).indexOf(".") >= 0;
            }
        }, filter((tupledArg_3) => endsWith(tupledArg_3[0].toLowerCase(), "weight"), formData.entries())) ? 100 : 1) | 0;
        const dimTryCreate = (isContainer, name) => {
            const unitMultiplier = isContainer ? patternInput[1] : patternInput[0];
            return Calculation_Dim_TryCreate_Z5095E5A6(formData.get(name), toInt64(fromInt32(endsWith(name.toLowerCase(), "weight") ? 1 : multiplier)), name, unitMultiplier);
        };
        let input_36;
        const input_35 = dimTryCreate(true, "container-length");
        if (input_35.tag === 1) {
            input_36 = (new FSharpResult$2(1, [input_35.fields[0]]));
        }
        else {
            const input_33 = dimTryCreate(true, "container-width");
            if (input_33.tag === 1) {
                input_36 = (new FSharpResult$2(1, [input_33.fields[0]]));
            }
            else {
                const input_31 = dimTryCreate(true, "container-height");
                if (input_31.tag === 1) {
                    input_36 = (new FSharpResult$2(1, [input_31.fields[0]]));
                }
                else {
                    const input_29 = dimTryCreate(true, "front-space");
                    if (input_29.tag === 1) {
                        input_36 = (new FSharpResult$2(1, [input_29.fields[0]]));
                    }
                    else {
                        const input_27 = dimTryCreate(true, "back-space");
                        if (input_27.tag === 1) {
                            input_36 = (new FSharpResult$2(1, [input_27.fields[0]]));
                        }
                        else {
                            const input_25 = dimTryCreate(true, "left-space");
                            if (input_25.tag === 1) {
                                input_36 = (new FSharpResult$2(1, [input_25.fields[0]]));
                            }
                            else {
                                const input_23 = dimTryCreate(true, "right-space");
                                if (input_23.tag === 1) {
                                    input_36 = (new FSharpResult$2(1, [input_23.fields[0]]));
                                }
                                else {
                                    const input_21 = dimTryCreate(true, "top-space");
                                    if (input_21.tag === 1) {
                                        input_36 = (new FSharpResult$2(1, [input_21.fields[0]]));
                                    }
                                    else {
                                        const treshold = new Calculation_Tresholds(input_25.fields[0], input_23.fields[0], input_21.fields[0], input_29.fields[0], input_27.fields[0]);
                                        const input_19 = Calculation_Weight_TryCreate_Z5095E5A6(formData.get("container-maxWeight"), toInt64(fromInt32(weightMultiplier)), "container Max Weight", patternInput_1[1]);
                                        if (input_19.tag === 1) {
                                            input_36 = (new FSharpResult$2(1, [input_19.fields[0]]));
                                        }
                                        else {
                                            const input_17 = sequenceValidationA(toList(map((tupledArg_7) => {
                                                const k_5 = tupledArg_7[0];
                                                const itemName = formData.get(k_5 + "-name");
                                                const input_10 = dimTryCreate(false, k_5 + "-length");
                                                if (input_10.tag === 1) {
                                                    return new FSharpResult$2(1, [input_10.fields[0]]);
                                                }
                                                else {
                                                    const input_8 = dimTryCreate(false, k_5 + "-width");
                                                    if (input_8.tag === 1) {
                                                        return new FSharpResult$2(1, [input_8.fields[0]]);
                                                    }
                                                    else {
                                                        const input_6 = dimTryCreate(false, k_5 + "-height");
                                                        if (input_6.tag === 1) {
                                                            return new FSharpResult$2(1, [input_6.fields[0]]);
                                                        }
                                                        else {
                                                            const input_4 = Calculation_Quantity_TryCreate_27AED5E3(formData.get(k_5 + "-quantity"));
                                                            if (input_4.tag === 1) {
                                                                return new FSharpResult$2(1, [input_4.fields[0]]);
                                                            }
                                                            else {
                                                                const priority = parse(formData.get(k_5 + "-priority"), 511, false, 32) | 0;
                                                                const color = formData.get(k_5 + "-color");
                                                                const stack = formData.get(k_5 + "-stack") === "on";
                                                                const keepTop = formData.get(k_5 + "-keeptop") === "on";
                                                                const keepBottom = formData.get(k_5 + "-keepbottom") === "on";
                                                                const input_2 = Calculation_Weight_TryCreate_Z5095E5A6(formData.get(k_5 + "-weight"), toInt64(fromInt32(weightMultiplier)), "", patternInput_1[0]);
                                                                if (input_2.tag === 1) {
                                                                    return new FSharpResult$2(1, [input_2.fields[0]]);
                                                                }
                                                                else {
                                                                    return new FSharpResult$2(0, [new Calculation_Item(new Calculation_LWH(input_10.fields[0], input_8.fields[0], input_6.fields[0]), input_2.fields[0], (((color + "?") + itemName) + "~") + k_5, input_4.fields[0], priority, keepTop, stack, keepBottom)]);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }, groupBy((tupledArg_6) => {
                                                const k_4 = tupledArg_6[0];
                                                return substring(k_4, 0, k_4.lastIndexOf("-"));
                                            }, filter((tupledArg_5) => (tupledArg_5[0].indexOf("item-") === 0), formData.entries()), {
                                                Equals: (x_1, y_1) => (x_1 === y_1),
                                                GetHashCode: stringHash,
                                            }))));
                                            if (input_17.tag === 1) {
                                                input_36 = (new FSharpResult$2(1, [input_17.fields[0]]));
                                            }
                                            else {
                                                const container = new Calculation_Container(new Calculation_LWH(input_35.fields[0], input_33.fields[0], input_31.fields[0]), input_19.fields[0]);
                                                let containerMode;
                                                const matchValue_2 = formData.get("container-mode");
                                                switch (matchValue_2) {
                                                    case "Single": {
                                                        containerMode = (new Calculation_ContainerMode(0, []));
                                                        break;
                                                    }
                                                    case "Multiple": {
                                                        containerMode = (new Calculation_ContainerMode(1, []));
                                                        break;
                                                    }
                                                    default:
                                                        throw new Error("cannot happen");
                                                }
                                                let quantityMode;
                                                if (length(model.Rows) > 1) {
                                                    quantityMode = false;
                                                }
                                                else {
                                                    const matchValue_3 = formData.get("quantity-mode");
                                                    switch (matchValue_3) {
                                                        case "Normal": {
                                                            quantityMode = false;
                                                            break;
                                                        }
                                                        case "Max": {
                                                            quantityMode = true;
                                                            break;
                                                        }
                                                        default:
                                                            throw new Error("cannot happen");
                                                    }
                                                }
                                                let calcMode;
                                                const matchValue_4 = formData.get("calc-mode");
                                                switch (matchValue_4) {
                                                    case "MinimizeLength": {
                                                        calcMode = (new Calculation_CalcMode(0, []));
                                                        break;
                                                    }
                                                    case "MinimizeHeight": {
                                                        calcMode = (new Calculation_CalcMode(1, []));
                                                        break;
                                                    }
                                                    default:
                                                        throw new Error("cannot happen");
                                                }
                                                let speedMode;
                                                const matchValue_5 = formData.get("speed-mode");
                                                switch (matchValue_5) {
                                                    case "Fast": {
                                                        speedMode = (new Calculation_Speed(2, []));
                                                        break;
                                                    }
                                                    case "Normal": {
                                                        speedMode = (new Calculation_Speed(1, []));
                                                        break;
                                                    }
                                                    case "Slow": {
                                                        speedMode = (new Calculation_Speed(0, []));
                                                        break;
                                                    }
                                                    default:
                                                        throw new Error("cannot happen");
                                                }
                                                let stackMode;
                                                const matchValue_6 = formData.get("stack-mode");
                                                switch (matchValue_6) {
                                                    case "Allow": {
                                                        stackMode = (new Calculation_AllowStacking(0, []));
                                                        break;
                                                    }
                                                    case "Disallow": {
                                                        stackMode = (new Calculation_AllowStacking(1, []));
                                                        break;
                                                    }
                                                    default:
                                                        throw new Error("cannot happen");
                                                }
                                                let input_15;
                                                const input_11 = Calculation_BinDrakeInput_TryCreate_Z412DF242(container, input_17.fields[0], new Calculation_Multiplier(toInt64(fromInt32(multiplier))), new Calculation_Multiplier(toInt64(fromInt32(weightMultiplier))), kvp_1, model.Rows, model.NextRowIndex, containerMode, calcMode, quantityMode, treshold, stackMode, speedMode, containerUnitText);
                                                input_15 = ((input_11.tag === 1) ? (new FSharpResult$2(1, [singleton(input_11.fields[0])])) : (new FSharpResult$2(0, [input_11.fields[0]])));
                                                input_36 = ((input_15.tag === 1) ? (new FSharpResult$2(1, [input_15.fields[0]])) : (new FSharpResult$2(0, [input_15.fields[0]])));
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (input_36.tag === 1) {
            const e_18 = input_36.fields[0];
            const nonGracefulErrors = filter_1((e_19) => {
                if (e_19.tag === 1) {
                    return false;
                }
                else {
                    return true;
                }
            }, e_18);
            if (length(nonGracefulErrors) > 0) {
                const message = join(", ", map_1(toString, nonGracefulErrors));
                navigator.sendBeacon("/log-error", message);
                window.showError(message);
            }
            else {
                const matchValue_7 = head(filter_1((e_21) => {
                    if (e_21.tag === 1) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }, e_18));
                if (matchValue_7.tag === 1) {
                    dispatch(new Msg(7, [matchValue_7.fields[0]]));
                }
                else {
                    throw new Error("cannot happen");
                }
            }
        }
        else {
            Lit_LitElement__LitElement_dispatchCustomEvent_1EA4E0D4(host, "Calculating", undefined, true, true);
            dispatch(new Msg(2, [input_36.fields[0], new Calculation_Multiplier(toInt64(fromInt32(multiplier))), kvp_1]));
        }
    };
}

